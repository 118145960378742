// app/javascript/components/Sidebar.js
import React, { useEffect, useState, useRef } from 'react';
import DagDiagram from './DagDiagram';
import FullNodeModal from './FullNodeModal';
import ReadNodeModal from './ReadNodeModal';
import Sidebar from './Sidebar';

const ProjectOverlay = ({ name, accessLevel, data, diagramId, isNewDiagram, organisationName, d3link }) => {
    const [modalEditMode, setModalEditMode] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDagNode, setSelectedDagNode] = useState(null);

    const [allDagNodes, setAllDagNodes] = useState([]);

    useEffect(() => {
        // Update all of the schedule data so that it is in timezone 
        // using the offset and adjust times for local displaying
        // This is for the future
      setAllDagNodes(data === null ? [] : data);
    }, []);


    const openModal = (node, id = null) => {
        const selectedNode = id ? data.find(item => item.id === id) : node;
        setSelectedDagNode(selectedNode);
        setModalEditMode(id !== null ? true : false);
        setIsModalOpen(true);
    };
    
    // Runs when modalEditMode changes
    useEffect(() => {
        if (modalEditMode) {
            var dropdownSelect = document.getElementById("dagNodeSelector");
            dropdownSelect.value = selectedDagNode.name;
            dropdownSelect.setAttribute("value", selectedDagNode.name);
        }
    }, [modalEditMode])

    const closeModal = () => {
        setSelectedDagNode(null);
        setModalEditMode(false);
        setIsModalOpen(false);
    };
    
    // Closing sidebar when clicking outside of it
    const sidebarRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            const sidebar = document.querySelector(".sidebar");
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                if (!sidebar.classList.contains("hidden")) {
                    sidebar.classList.toggle("hidden");
                }
            }
        }
        var graph = document.getElementById("graph");
        // Bind the event listener
        graph.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            graph.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sidebarRef]);
    // END Closing sidebar when clicking outside of it

    useEffect(() => {
        const changeButtonColor = setTimeout(() => {
            let button = document.getElementById("refreshButton");
            button.style.backgroundColor = "red"; // Change the color to your desired value
        }, 300000);

        return () => {
            clearTimeout(changeButtonColor);
        };
    }, []);

return (
    <>
    {/* ToolTip Component */}
    {/* <div className="tooltip" id="tooltip"></div> */}
    {/* DagDiagram Component */}
    <DagDiagram name={name} data={data} d3link={d3link} openModal={openModal} ></DagDiagram>

    {/* Modal Component */}
    {/* Full */}
    {(accessLevel === 4) && (
        <FullNodeModal isOpen={isModalOpen} onClose={closeModal} dagNode={selectedDagNode} allDagNodes={data} diagramId={diagramId} modalEditMode={modalEditMode} ></FullNodeModal>
    )}
    {/* TODO Shared */}
    {false && (
        <p>SharedModal</p>
    )}
    {/* TODO Partial */}
    {false && (
        <p>HELLO there</p>
    )}
    {/* Read */}
    {(accessLevel === 1) && (
        <ReadNodeModal isOpen={isModalOpen} onClose={closeModal} dagNode={selectedDagNode} allDagNodes={data} diagramId={diagramId} modalEditMode={modalEditMode} ></ReadNodeModal>
    )}
    
    {/* Sidebar Component - Under so that it is clickable */}
    {/* TODO add permission levels */}
    <Sidebar ref={sidebarRef} openModal={openModal} accessLevel={accessLevel} allDagNodes={allDagNodes} diagramId={diagramId} diagramName={name} isNewDiagram={isNewDiagram} organisationName={organisationName}></Sidebar>
    
    <div style={{ zIndex: 10, position: 'fixed', bottom: 0, left: 0, padding: '10px', color: '#c9c7c7' }}>
        <button id="refreshButton" onClick={() => window.location.reload()} className="mr-2 bg-grey-500 hover:bg-grey-700 text-white font-bold py-2 px-4 rounded" style={{ fontSize: '20px' }}>
            Refresh
        </button>

        Page rendered: <strong>{new Date().toLocaleString()}</strong>
    </div>
    {(accessLevel === 4) && (<>
        <div style={{ zIndex: 50, position: 'fixed', bottom: 0, right: 0, padding: '10px', color: '#404756' }}>
            <i className="bi bi-node-plus pr-8 cursor-pointer" onClick={() => openModal({
                        name: '',
                        description: '',
                        duedate: '',
                        completed_at: '',
                        notes: '',
                        parentIds: [],
                        diagram_Id: diagramId,
                    })} style={{ fontSize: '60px' }}></i>
        </div>
        </>
    )}
    </>
)
}
export default ProjectOverlay;