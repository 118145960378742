// app/javascript/components/Sidebar.js
import React, { useEffect, useState, forwardRef } from 'react';

const Sidebar = forwardRef((props, ref) => {
    const { openModal, accessLevel, allDagNodes, diagramId, diagramName, isNewDiagram, organisationName } = props;

    // const dropdown = () => {
    //     const submenu = document.querySelector("#submenu");
    //     const arrow = document.querySelector("#arrow");
    //     if (submenu && arrow) {
    //         submenu.classList.toggle("hidden");
    //         arrow.classList.toggle("rotate-180");
    //     }
    // }

  const openSidebar = () => {
    document.querySelector(".sidebar").classList.toggle("hidden");
  }

//   function saveDiagram(asNew) {
//     const csrfToken = document.querySelector("[name=csrf-token]").content;
//     let newDiagramName = null;
//     if (diagramName === null || isNewDiagram || asNew) {
//         newDiagramName = prompt("What would you like to name the diagram?", ""); 
//     } else {
//         newDiagramName = diagramName;
//     }
//     if (newDiagramName === null || newDiagramName === undefined || newDiagramName == "") {
//         window.alert("You must enter a name for the diagram in order to save it");
//         return
//     }
//     const diagramData = { diagramName: newDiagramName, id: asNew ? null : diagramId, dagData: allDagNodes}; 
//     fetch("/diagrams", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             "X-CSRF-Token": csrfToken,
//         },
//         body: JSON.stringify(diagramData),
//         })
//         .then((response) => response.json())
//         .then((data) => {
//             alert("You just saved the diagram!");
//         })
//         .then(() => {
//             window.location.reload();
//         })
//         .catch((error) => {
//             alert("You cannot save a new diagram with the same name");
//         });
//   }

useEffect(() => {
}   , []);

return (
    <div ref={ref} style={{ zIndex: 20}}>
        <span
            className="absolute text-white text-4xl top-12 left-12 cursor-pointer"
            onClick={openSidebar}
        >
            <i style={{ backgroundColor: '#6A6E70' }} className="bi bi-list px-2 rounded-md"></i>
        </span>

        <div
            className="hidden sidebar fixed top-0 bottom-0 lg:left-0 p-2 overflow-y-auto text-center rounded"
            style={{ backgroundColor: '#6A6E70', zIndex: 20}}
        >
            <div className="text-gray-100 text-xl">
                <div className="p-2.5 mt-1 flex items-center">
                    <i className="bi bi-balloon px-2 py-1 rounded-md bg-mainbggreen"></i>
                    <h1 className="font-bold text-gray-200 text-[15px] ml-3">{organisationName}</h1>
                    <i
                        className="bi bi-x cursor-pointer ml-28"
                        onClick={openSidebar}
                    ></i>
                </div>
            </div>
            {/* <div
                className="p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer bg-gray-700 text-white"
            >
                <i className="bi bi-search text-sm"></i>
                <input id="sidebarSearch"
                    type="text"
                    placeholder="Search"
                    className="text-[15px] ml-4 w-full bg-transparent focus:outline-none"
                />
            </div> */}
            <div
                className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-mainbggreen15white text-white" onClick={() => window.location.href="/"}
            >
                <i className="bi bi-house-door-fill"></i>
                <span className="text-[15px] ml-4 text-gray-200 font-bold">Home</span>
            </div>
            
            {(accessLevel > 1) && <>
                <div className="my-4 bg-gray-600 h-[1px]"></div>
                </>
            }

            {(accessLevel === 4) && (<>
                <div
                    className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-softbluegrey text-white" onClick={() => openModal({
                        name: '',
                        description: '',
                        duedate: '',
                        completed_at: '',
                        notes: '',
                        parentIds: [],
                        diagram_Id: diagramId,
                    })}
                >
                <i className="bi bi-node-plus"></i>
                    <span className="text-[15px] ml-4 text-gray-200 font-bold">Create Node</span>
                </div>
            </>
            )}

            {(accessLevel > 1) && (<>
                <div
                    className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-softbluegrey text-white" onClick={() => openModal(null)}
                >
                    <i className="bi bi-wrench-adjustable"></i>
                    <span className="text-[15px] ml-4 text-gray-200 font-bold">Edit Node</span>
                </div>
                </>
            )}
            {/* <div
                className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-400 text-white" onClick={() => saveDiagram(false)}
            >
                <i className="bi bi-sd-card"></i>
                <span className="text-[15px] ml-4 text-gray-200 font-bold">Save Diagram</span>
            </div> */}
            {/* <div
                className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-400 text-white" onClick={() => saveDiagram(true)}
            >
                <i className="bi bi-sd-card-fill"></i>
                <span className="text-[15px] ml-4 text-gray-200 font-bold">Save as New Diagram</span>
            </div> */}
            {/* <div className="my-4 bg-gray-600 h-[1px]"></div>
            <div
                className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-400 text-white"
                onClick={dropdown}
            >
                <i className="bi bi-chat-left-text-fill"></i>
                <div className="flex justify-between w-full items-center">
                    <span className="text-[15px] ml-4 text-gray-200 font-bold">Settings</span>
                    <span className="text-sm" id="arrow">
                        <i id="arrow" className="bi bi-chevron-down"></i>
                    </span>
                </div>
            </div>
            <div
                className="hidden text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 font-bold"
                id="submenu"
            >
                <h1 className="cursor-pointer p-2 hover:bg-blue-400 rounded-md mt-1">
                    Social
                </h1>
                <h1 className="cursor-pointer p-2 hover:bg-blue-400 rounded-md mt-1">
                    Personal
                </h1>
                <h1 className="cursor-pointer p-2 hover:bg-blue-400 rounded-md mt-1">
                    Friends
                </h1>
            </div> p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-400 text-white*/}
            
            <div className="my-4 bg-gray-600 h-[1px]"></div>
            
            <div
                    className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-softbluegrey text-white" onClick={() => window.open("/documentation", "_blank")}
                >
                    <i className="bi bi-window-plus"></i>
                    <span className="text-[15px] ml-4 text-gray-200 font-bold">Documentation</span>
            </div>

            <div
                className="absolute block bottom-0 p-2.5 mt-3 mb-6 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-earthybrown text-white"
            >
                <i className="bi bi-box-arrow-in-right"></i>
                {/* Copy from what is put via the rails code: <%= link_to "Sign out", destroy_user_session_path, method: :delete, data: { turbo_method: :delete}, class: "btn btn-blue" %> */}
                <a  data-turbo-method="delete" data-method="delete" href="/user/sign_out" className="text-[15px] ml-4 text-gray-200 font-bold" rel="nofollow">Log out</a>
            </div>
        </div>
    </div>
    )
});
export default Sidebar;