// ReadNodeModal.js
import React, { useState, useEffect } from "react";
import "./Modal.css"; // Import the CSS file
import ScheduleModal from "./ScheduleModal";

const ReadNodeModal = ({ isOpen, onClose, dagNode, allDagNodes, diagramId, modalEditMode }) => {
  //TODO Add on close check on this variable
  const [isNodeEdited, setIsNodeEdited] = useState(false);
  const [scheduleTempValue, setScheduleTempValue] = useState(null);
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [selectedParentNodeId, setSelectedParentNodeId] = useState(null);
  const [dagNodeData, setDagNodeData] = useState({
    name: "",
    description: "",
    duedate: "",
    completed_at: "",
    notes: "",
    parentIds: [],
    scheduleValue: null,
    diagramId: diagramId,
    next_reset_at: "",
    last_reset_at: "",
  });

  const nameInUseMessage = "The name you entered for this node is already in use - the node has not been ";

  useEffect(() => {
    if (isOpen && allDagNodes?.length > 0) {
      setDagNodeData(allDagNodes[0]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (dagNode !== null) {
      setDagNodeData(dagNode);
      setScheduleTempValue(dagNode.scheduleValue !== null ? dagNode.scheduleValue : null);
    } else {
      setDagNodeData({
        name: "",
        description: "",
        duedate: "",
        completed_at: "",
        notes: "",
        parentIds: [],
        scheduleValue: null,
        diagramId: diagramId,
      });
    }
  }, [dagNode]);


  if (!isOpen) return null;

  const handleChange = (e) => {
    setDagNodeData({ ...dagNodeData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    const selectedNodeName = e.target.value;
    const selectedNode = allDagNodes?.find(node => node.name === selectedNodeName);
    setDagNodeData(selectedNode);
  };

  function formatDateString(dateString) {
    var t = dateString.split(/[- :]/);
    // Apply each element to the Date function
    return t[0] + "-" + t[1] + "-" + t[2];
  };

  const scheduleTempValueAsString = () => {
    if (scheduleTempValue !== null && scheduleTempValue !== undefined && scheduleTempValue !== ""
        && scheduleTempValue["reset_schedule"] !== null && scheduleTempValue["reset_schedule"] !== undefined && scheduleTempValue["reset_schedule"] !== "") {
        return scheduleTempValue["reset_schedule"] + (scheduleTempValue["is_reoccurring"] ? " - Reoccurring" : " - One-off");
    }
    return "No Schedule Set";
  }

  return (
    <div className="overflow-y-auto flex h-screen flex-col flex-grow modal fixed inset-0 items-center justify-center z-50">
      <div style={{ backgroundColor: '#f1f0f5', height: '80%', width: '50%' }} className="flex flex-col h-screen overflow-hidden overflow-y-auto bg-white rounded-lg shadow-lg p-4">
        <header class="w-full text-center border-b border-grey p-4">
          <div className="flex justify-between items-center">
            <h1 className="modal-title text-2xl font-semibold text-gray-800">{(dagNode === null || modalEditMode) ? "Edit Node" : "Create Node"}</h1>
            <div>
                <i className="bi bi-x-lg cursor-pointer" onClick={onClose}></i>
            </div>
          </div>
        </header>
        <main class="flex-1 overflow-y-scroll pt-1">
          { (dagNode === null || modalEditMode) && 
              <>
              <label htmlFor="dagNode" className="mb-2 font-semibold text-gray-700">Node: </label>
              <select id="dagNodeSelector" name="dagNode" onChange={handleSelectChange} className="border border-gray-300 p-2 rounded">
                  {allDagNodes?.map((node, index) => (
                      <option key={index} value={node.name}>{node.name}</option>
                  ))}
              </select>
              </>
          }
          <form id="nodeModal">

            <div className="my-4 bg-gray-600 h-[1px]"></div>

            <div className="flex flex-col">
              <label htmlFor="name" className="mb-2 font-semibold text-gray-700">Name</label>
              <span
                className="mb-2"
                name="name"
              >{dagNodeData.name}</span>

              <div className="my-4 bg-gray-600 h-[1px]"></div>

              <label htmlFor="description" className="mb-2 font-semibold text-gray-700">Description</label>
              <span
                className="mb-2"
                name="description"
              >{dagNodeData.description}</span>

              <div className="my-4 bg-gray-600 h-[1px]"></div>

              <label htmlFor="duedate" className="mb-2 font-semibold text-gray-700">Due Date</label>
              <div className="flex justify-between">
                <span
                  id="duedate"
                  name="duedate"
                  className="mb-2" 
                >{dagNodeData.duedate ? formatDateString(dagNodeData.duedate) : "No Due Date"}</span>
              </div>

              <div className="my-4 bg-gray-600 h-[1px]"></div>

              <label htmlFor="completed_at" className="mb-2 font-semibold text-gray-700">Completed On</label>
              <div className="flex justify-between">
                <span
                  id="completed_at"
                  name="completed_at"
                  className="mb-2" 
                >{dagNodeData.completed_at ? formatDateString(dagNodeData.completed_at) : "Not Marked as Completed"}</span>
              </div>

              <div className="my-4 bg-gray-600 h-[1px]"></div>

              <label htmlFor="notes" className="mb-2 font-semibold text-gray-700">Notes</label>
              <span
                className="mb-2" 
                name="notes"
              >{dagNodeData.notes}</span>

              <div className="my-4 bg-gray-600 h-[1px]"></div>

              <label htmlFor="dagNodeParents" className="mb-2 font-semibold text-gray-700">Parent Links: </label>
              {dagNodeData.parentIds.map((id, index) => {
                const node = allDagNodes?.find(node => node.id === id);
                return (
                  <div key={index} className="flex items-center mb-2">
                    <span className="mr-2">{node.name}</span>
                  </div>
                );
              })}

              <div className="my-4 bg-gray-600 h-[1px]"></div>
                     
              <label htmlFor="scheduleModal" className="mb-2 font-semibold text-gray-700">Completed At Reset Schedule: </label>
              <div className="flex justify-between">
                  <p name="scheduleValue" value={scheduleTempValue}>{scheduleTempValueAsString()}</p>
              </div>
            </div>
            { (dagNodeData["scheduleValue"] !== null && dagNodeData["scheduleValue"] !== undefined && dagNodeData["scheduleValue"]["next_reset_at"] !== "" && dagNodeData["scheduleValue"]["next_reset_at"] !== null) && (
            <> 
              <br></br>
              <p name="nextResetAt" value={dagNodeData["next_reset_at"]}>Next Reset at: {dagNodeData["next_reset_at"]}</p>
            </>)
            }
            { (dagNodeData["scheduleValue"] !== null && dagNodeData["scheduleValue"] !== undefined && dagNodeData["scheduleValue"]["last_reset_at"] !== "" && dagNodeData["scheduleValue"]["last_reset_at"] !== null) && (
            <> 
              <br></br>
              <p name="lastResetAt" value={dagNodeData["last_reset_at"]}>Last Reset at: {dagNodeData["last_reset_at"]}</p>
            </>)
            }
            <br></br>
          </form>
        </main>
        <footer class="w-full text-center border-t border-grey p-2">
          
        </footer>
      </div>
    </div>
  );
};

export default ReadNodeModal;
