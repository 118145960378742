// ScheduleModal.js
import React, { useState, useEffect } from "react";

const ScheduleModal = ({ setscheduleValue, isScheduleModalOpen, closeScheduleModal, existingSchedule}) => {
  const [isReoccurring, setIsReoccurring] = useState(false);
  const [selectedReoccurringOption, setSelectedReoccurringOption] = useState("daily");
  const [tzOffSet, setTzOffSet] = useState((new Date().getTimezoneOffset() / 60)*-1);
  const [timeComponent, setTimeComponent] = useState("00:00");
  const [weeklySelections, setWeeklySelections] = useState("Monday");
  const [monthlyDaySelection, setMonthlyDaySelection] = useState(1);
  const [yearlyMonthSelection, setYearlyMonthSelection] = useState("January");
  const [oneOffTime, setOneOffTime] = useState("");
  
  useEffect(() => {
    if (existingSchedule !== null && existingSchedule !== undefined && existingSchedule["is_reoccurring"] !== null && existingSchedule["reset_schedule"] !== null) {
      setIsReoccurring(existingSchedule["is_reoccurring"]);
      const scheduleParts = existingSchedule["reset_schedule"].split(" ");
      if (existingSchedule["is_reoccurring"]) {
        setupScheduleComponents(scheduleParts);
      }else {
        setOneOffTime(existingSchedule["reset_schedule"].split(' ')[0]); // exclude offset part
      }
      setTzOffSet((new Date().getTimezoneOffset() / 60)*-1)//setTzOffSet(existingSchedule["tzoffset"])
    }else {
      setIsReoccurring(false);
      setSelectedReoccurringOption("daily");
      setTzOffSet((new Date().getTimezoneOffset() / 60)*-1);
    }
  }, [existingSchedule]);

  // Return early if not open
  if (!isScheduleModalOpen) return null;

  // This should not be called if the schedule is not reoccurring
  function setupScheduleComponents(scheduleParts) {
    setSelectedReoccurringOption(scheduleParts[0].toLowerCase());
    setTimeComponent(scheduleParts[1]);
    switch(scheduleParts[0].toLowerCase()) {
      case "weekly":
        setWeeklySelections(scheduleParts[2]);
        break;
      case "monthly":
        setMonthlyDaySelection(scheduleParts[2]);
        break;
      case "yearly":
        setMonthlyDaySelection(scheduleParts[2]);
        setYearlyMonthSelection(scheduleParts[3]);
        break;
    }
  }

  function scheduleString(){
    var plusMinus = tzOffSet >= 0 ? "+" : "-";
    var hrs = " (UTC " + plusMinus + Math.abs(tzOffSet).toString() + " hrs)"
    console.log(tzOffSet)
    if (isReoccurring){
      switch(selectedReoccurringOption) {
        case "daily":
          return "Daily " + timeComponent + hrs;
        case "weekly":
          return "Weekly " + timeComponent + " " + weeklySelections + hrs;
        case "monthly":
          return "Monthly " + timeComponent + " " + monthlyDaySelection + hrs;
        case "yearly":
          return "Yearly " + timeComponent + " " + monthlyDaySelection + " " + yearlyMonthSelection + hrs;
      }
    } else {
      return oneOffTime + hrs;
    }
  }

  function setScheduleValueInModal() {
    setscheduleValue(
                    {
                      "reset_schedule" : scheduleString(),
                      "is_reoccurring": isReoccurring,
                      "tzoffset": tzOffSet
                    } 
                    );
    
    closeScheduleModal();
  }

  function getReoccurringValueFromValue(value) {
    if (!isReoccurring) {
      return "";
    }
    return value.split(" ")[1];
  }

  function toggleIsReoccurring() {
    setIsReoccurring(!isReoccurring);
  }

  function clearDatefield() {
    document.getElementById("oneOffDate").value = "";
    document.getElementById("oneOffDate").setAttribute("value", "");
  }

  function updateWeeklyValue() {
    var weeklyvalues = "";//reOccurringWeeklyMonday
    if (document.getElementsByName("reOccurringWeeklyMonday")[0].checked) {
      weeklyvalues += "Monday-";
    } 
    if (document.getElementsByName("reOccurringWeeklyTuesday")[0].checked) {
      weeklyvalues += "Tuesday-";
    }
    if (document.getElementsByName("reOccurringWeeklyWednesday")[0].checked) {
      weeklyvalues += "Wednesday-";
    }
    if (document. getElementsByName("reOccurringWeeklyThursday")[0].checked) {
      weeklyvalues += "Thursday-";
    }
    if (document.getElementsByName("reOccurringWeeklyFriday")[0].checked) {
      weeklyvalues += "Friday-";
    }
    if (document.getElementsByName("reOccurringWeeklySaturday")[0].checked) {
      weeklyvalues += "Saturday-";
    }
    if (document.getElementsByName("reOccurringWeeklySunday")[0].checked) {
      weeklyvalues += "Sunday";
    } else if (weeklyvalues.length > 0) {
      weeklyvalues = weeklyvalues.substring(0, weeklyvalues.length - 1);
    }
    setWeeklySelections(weeklyvalues);
  }

  function isChecked(day) {
    return weeklySelections.includes(day);
  }

  function submitClick() {
    if (selectedReoccurringOption === "weekly" && weeklySelections === "") {
      alert("Please select at least one day for the weekly schedule");
    } else {
    setScheduleValueInModal();
    }
  }
  
  return (
    <div className="modal fixed inset-0 flex items-center justify-center z-50">
      <div style={{ backgroundColor: '#f1f0f5', height: '90%', width: '60%'  }} className="bg-white rounded-lg shadow-lg p-6 w-1/2 h-full">
      
      <h1 className="modal-title text-2xl font-semibold text-gray-800">Node Schedule</h1>

      <div  className="mt-5">    
        <label htmlFor="isReoccurring">Is Event Reoccurring</label>
        <input
          type="checkbox"
          name="isreoccurring"
          value={isReoccurring}
          checked={isReoccurring}
          onChange={() => toggleIsReoccurring()}
          className="ml-2"
        />
      </div>
      <br></br>
      { isReoccurring ? (
          <div>
          <label htmlFor="reoccurringOption">Reoccurring Option</label>
          <select
            id="reoccurringOption"
            name="reoccurringOption"
            value={selectedReoccurringOption}
            onChange={(event) => setSelectedReoccurringOption(event.target.value)}
            className="ml-2"
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
          <br></br>
          {
            {
              "daily": <div className="mt-5">
                        <label htmlFor="reOccurringDailyTime">Repeats at:</label>
                        <input
                          type="time"
                          id="reOccurringDailyTime"
                          name="reOccurringDailyTime"
                          value={timeComponent}
                          onChange={(event) => setTimeComponent(event.target.value)}
                          className="flex-grow ml-2 mr-1" 
                        />
                       </div>,
              "weekly": <div className="mt-5">
                          <label htmlFor="reOccurringWeeklyDays">Repeated on:</label>
                          <br></br>
                          <input checked={isChecked("Monday")} onChange={updateWeeklyValue} type="checkbox" name="reOccurringWeeklyMonday" value="Monday" className="ml-2 mr-1" />
                          <label htmlFor="reOccurringWeeklyMonday"> Monday</label>
                          <br></br>
                          <input checked={isChecked("Tuesday")} onChange={updateWeeklyValue} type="checkbox" name="reOccurringWeeklyTuesday" value="Tuesday" className="ml-2 mr-1" />
                          <label htmlFor="reOccurringWeeklyTuesday"> Tuesday</label>
                          <br></br>
                          <input checked={isChecked("Wednesday")} onChange={updateWeeklyValue} type="checkbox" name="reOccurringWeeklyWednesday" value="Wednesday" className="ml-2 mr-1" />
                          <label htmlFor="reOccurringWeeklyWednesday"> Wednesday</label>
                          <br></br>
                          <input checked={isChecked("Thursday")} onChange={updateWeeklyValue} type="checkbox" name="reOccurringWeeklyThursday" value="Thursday" className="ml-2 mr-1" />
                          <label htmlFor="reOccurringWeeklyThursday"> Thursday</label>
                          <br></br>
                          <input checked={isChecked("Friday")} onChange={updateWeeklyValue} type="checkbox" name="reOccurringWeeklyFriday" value="Friday" className="ml-2 mr-1" />
                          <label htmlFor="reOccurringWeeklyFriday"> Friday</label>
                          <br></br>
                          <input checked={isChecked("Saturday")} onChange={updateWeeklyValue} type="checkbox" name="reOccurringWeeklySaturday" value="Saturday" className="ml-2 mr-1" />
                          <label htmlFor="reOccurringWeeklySaturday"> Saturday</label>
                          <br></br>
                          <input checked={isChecked("Sunday")} onChange={updateWeeklyValue} type="checkbox" name="reOccurringWeeklySunday" value="Sunday" className="ml-2 mr-1" />
                          <label htmlFor="reOccurringWeeklySunday"> Sunday</label>
                          <br></br>
                          <div className="mt-5" >
                          <label htmlFor="reOccurringWeeklyTime">Time: </label>
                          <input
                            type="time"
                            id="reOccurringWeeklyTime"
                            name="reOccurringWeeklyTime"
                            value={timeComponent}
                            onChange={(event) => setTimeComponent(event.target.value)}
                            className="flex-grow mr-1" 
                          />
                          </div>
                        </div>,
              "monthly": <div className="mt-5">
                          <label htmlFor="reOccurringMonthly">Day to repeat on: </label>
                          <select id="number-dd" 
                                  name="number"
                                  onChange={(event) => setMonthlyDaySelection(event.target.value)}>
                          {Array.from({length: 31}, (_, i) => i + 1).map((number) => (
                            <option key={number} value={number}>{number}</option>
                          ))}
                          </select>
                          <div className="mt-5" >
                          <label htmlFor="reOccurringMonthlyTime">Time: </label>
                          <input
                            type="time"
                            id="reOccurringMonthlyTime"
                            name="reOccurringMonthlyTime"
                            value={timeComponent}
                            onChange={(event) => setTimeComponent(event.target.value)}
                            className="flex-grow mr-1" 
                          />
                          </div>
                        </div>
                        ,
              "yearly": <div className="mt-5">
                          <label htmlFor="reOccurringYearlyDay">Day of Month to Repeat: </label>
                          <select id="number-dd" 
                                  name="number"
                                  onChange={(event) => setMonthlyDaySelection(event.target.value)}
                                  value={monthlyDaySelection}
                          >
                          {Array.from({length: 31}, (_, i) => i + 1).map((number) => (
                            <option key={number} value={number}>{number}</option>
                          ))}
                          </select>
                          <br></br>
                          <div className="mt-5" >
                          <label className="mt-5" htmlFor="reOccurringYearlyMonth">Month to Repeat: </label>
                          <select id="number-dd" 
                                  name="number" 
                                  onChange={(event) => setYearlyMonthSelection(event.target.value)}
                                  value={yearlyMonthSelection}
                                  >
                          {["January", "February", "March", "April","May","June","July","August","September","October","November","December"].map((month) => (
                            <option key={month} value={month}>{month}</option>
                          ))}
                          </select>
                          </div>
                          <div className="mt-5" >
                          <label htmlFor="reOccurringYearlyTime">Time: </label>
                          <input
                            type="time"
                            id="reOccurringYearlyTime"
                            name="reOccurringYearlyTime"
                            value={timeComponent}
                            onChange={(event) => setTimeComponent(event.target.value)}
                            className="flex-grow mr-1" 
                          />
                          </div>
                        </div>,
            }[selectedReoccurringOption]
          } 
          </div>

        ) : (
          <div>
          <label htmlFor="oneOffDate">Completed at resets on:</label>
            <div className="flex justify-between">
              <input
                type="datetime-local"
                id="oneOffDate"
                name="oneOffDate"
                value={oneOffTime}
                onChange={(event) => setOneOffTime(event.target.value)}
                className="flex-grow mr-1" 
              />
              <button type="button" 
                      onClick={() => clearDatefield()} 
                      className="flex-shrink-0 ml-2 bg-zinc-500 hover:bg-zinc-700 text-white font-bold py-1 px-2 rounded object-right">
                        Clear
              </button>
            </div>
          </div>
        )
      }

      {/* //TODO Add Notification Area */}
      <div id="NotificationArea">
        <div  className="mt-5">    
          <label htmlFor="hasNotification">Schedule Notification:</label>
          <input
            type="checkbox"
            name="hasNotification"
            //value={hasNotification}
            //onChange={}
            className="ml-2"
          />
        </div>
      </div>

      <br></br>   

      <button type="button" 
              onClick={() => submitClick()} 
              className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded object-right">
                Confirm
      </button>
      <button type="button" 
        onClick={() => closeScheduleModal()} 
        className=" ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded object-right">
          Close
      </button>
      </div>
    </div>
  );
};

export default ScheduleModal;
