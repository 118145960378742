// app/javascript/components/DagDiagram.js
import React, { useEffect, useState } from 'react';

const DagDiagram = ({name, data, d3link, openModal}) => {

    function callback(){
        // Set diagram name
        var diagramNameElement = document.getElementById('diagramName');
        diagramNameElement.textContent = name;

        // Setup of the graph
        function arrowTransform({ points }) {
            const [[x1, y1], [x2, y2]] = points.slice(-2);
            const angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 90;
            return `translate(${x2}, ${y2}) rotate(${angle})`;
        }

        const builder = d3dag.graphStratify();
        const graph = builder(data);

        // Compute Layout
        const nodeRadius = 100;
        const nodeSize = [nodeRadius * 2, nodeRadius * 2];
        const shape = d3dag.tweakShape(nodeSize, d3dag.shapeEllipse);
        const line = d3.line().curve(d3.curveMonotoneY);

        const layout = d3dag
            .sugiyama()
            .nodeSize(nodeSize)
            .gap([nodeRadius, nodeRadius])
            .tweaks([shape]);

        const { width, height } = layout(graph);

        // Rendering
        const steps = graph.nnodes() - 1;
        const interp = d3.interpolateRainbow;

        const svg = d3.select("#graph")
            .style("width", "100%")
            .style("height", "100%");
        const trans = svg.transition().duration(750);
        //const tooltip = d3.select("#tooltip");

        // ------------------------------------------------------
        // START Hover methods while values are defined
        // ------------------------------------------------------
        // function handleMouseOver(event, d) {
        //     const circleId = `#circle-${d.data.id}`;
        //     const circle = svg.select(circleId);

        //     // Display the tooltip near the mouse cursor
        //     tooltip.style("display", "block")
        //     .style("left", event.pageX + "px")
        //     .style("top", event.pageY + "px")
        //     .text(`${d.data.description}\n
        //     Last Completed: ${d.data.completed_at !== "" ? d.data.completed_at : "Not Completed"}\n
        //     Notes: ${d.data.notes}`);

        //     // Update the circle appearance if needed
        //     circle.attr("fill", "#48b1f7");
        // }

        function handleMouseOut(event, d) {
            const circleId = `#circle-${d.data.id}`;
            const circle = svg.select(circleId);

            // Hide the tooltip
            //tooltip.style("display", "none");

            // Restore the original circle appearance
            circle.attr("fill", (n) => n.data.color);
        }
        function openTheModal(event, d){
            openModal(null, d.data.id)
        }

        function cancelTouch(event, d){
            d3.select(this).on('touchend', null);
        }
        // ------------------------------------------------------
        // END Hover methods while values are defined
        // ------------------------------------------------------

        svg
            .select("#nodes")
            .selectAll("g")
            .data(graph.nodes())
            .join((enter) =>
            enter
                .append("g")
                .attr("class", "cursor-pointer")
                .attr("transform", ({ x, y }) => `translate(${x}, ${y})`)
                .attr("opacity", 0)
                //.on("mouseover", handleMouseOver) // Add mouseover event listener // dont need hover now that click works
                .on("mouseout", handleMouseOut)   // Add mouseout event listener
                .on("click", openTheModal) // click event listener
                .on("touchend", openTheModal) // Touch screen click // better to use touchend so that it is less sensitive
                .on('touchmove', cancelTouch) // on Drag dont open modal
                .call((enter) => {
                enter
                    .append("circle")
                    .attr("r", nodeRadius)
                    .attr("fill", (n) => n.data.color)
                    .attr("id", (n) => `circle-${n.data.id}`); // Add ID for reference;

                const foreignObject = enter
                    .append("foreignObject")
                    .attr("width", nodeSize[0])
                    .attr("height", nodeSize[1])
                    .attr("x", -nodeRadius) // Adjust the x-coordinate
                    .attr("y", -nodeRadius) // Adjust the y-coordinate
                    .append("xhtml:div")
                    .style("width", "100%")
                    .style("height", "100%")
                    .style("display", "flex")
                    .style("align-items", "center")
                    .style("justify-content", "center")
                    .style("overflow", "hidden"); // Prevent text overflow

                foreignObject
                    .append("text")
                    .style("color", "white")
                    .style("font-weight", "bold")
                    .style("font-family", "sans-serif")
                    .style("text-align", "center")
                    //.style("vertical-align", "middle")
                    .style("max-width", nodeRadius * 1.5 + "px")
                    .style("max-height", nodeRadius * 1.5 + "px")
                    // .style("word-wrap", "break-word") // Enable text wrapping
                    .text((d) => d.data.name);

                enter.transition(trans).attr("opacity", 1);
            })
        );

        svg
            .select("#defs")
            .selectAll("linearGradient")
            .data(graph.links())
            .join((enter) =>
            enter
                .append("linearGradient")
                .attr("id", ({ source, target }) =>
                encodeURIComponent(`${source.data.id}--${target.data.id}`)
                )
                .attr("gradientUnits", "userSpaceOnUse")
                .attr("x1", ({ points }) => points[0][0])
                .attr("x2", ({ points }) => points[points.length - 1][0])
                .attr("y1", ({ points }) => points[0][1])
                .attr("y2", ({ points }) => points[points.length - 1][1])
                .call((enter) => {
                enter
                    .append("stop")
                    .attr("class", "grad-start")
                    .attr("offset", "0%")
                    .attr("stop-color", ({ source }) => source.data.color);
                enter
                    .append("stop")
                    .attr("class", "grad-stop")
                    .attr("offset", "100%")
                    .attr("stop-color", ({ target }) => target.data.color);
            })
        );

        svg
            .select("#links")
            .selectAll("path")
            .data(graph.links())
            .join((enter) =>
            enter
                .append("path")
                .attr("d", ({ points }) => line(points))
                .attr("fill", "none")
                .attr("stroke-width", 3)
                .attr(
                "stroke",
                ({ source, target }) => `url(#${source.data.id}--${target.data.id})`
                )
                .attr("opacity", 0)
                .call((enter) => enter.transition(trans).attr("opacity", 1))
        );

        const arrowSize = 80;
        const arrowLen = Math.sqrt((4 * arrowSize) / Math.sqrt(3));
        const arrow = d3.symbol().type(d3.symbolTriangle).size(arrowSize);
        svg
            .select("#arrows")
            .selectAll("path")
            .data(graph.links())
            .join((enter) =>
            enter
                .append("path")
                .attr("d", arrow)
                .attr("fill", ({ target }) => target.data.color)
                .attr("transform", arrowTransform)
                .attr("opacity", 0)
                .attr("stroke", "white")
                .attr("stroke-width", 2)
                .attr("stroke-dasharray", `${arrowLen},${arrowLen}`)
                .call((enter) => enter.transition(trans).attr("opacity", 1))
            );

        // Pan and zoom
        const zoom = d3.zoom().on("zoom", (event) => {
            svg.select("g").attr("transform", event.transform);
        }).scaleExtent([0.15,2]);
        svg.call(zoom);

    }

    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = d3link;
        script.onload = callback;
        document.head.appendChild(script);
    }, []);

return (
    <>
    {/* Create a container div for panning and zooming */}
    <div id="graph-container" style={{zIndex: 0}}>
        <svg id="graph">
            <g>
            <defs id="defs" />
            <g id="links" />
            <g id="nodes" />
            <g id="arrows" />
            </g>
        </svg>
    </div>


    {/* Title of system - Under so that it overlays */}
    <span id="diagramName" className="absolute text-white text-4xl top-12 right-12 cursor-pointer" style={{color: '#6A6E70'}}></span>
    </>
    )
}
export default DagDiagram;